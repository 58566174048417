var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.loading ? _c("loading") : _vm._e(),
      _c("add-device-dialog", {
        on: { "on-close": _vm.loadDevices },
        model: {
          value: _vm.addDeviceModal,
          callback: function($$v) {
            _vm.addDeviceModal = $$v
          },
          expression: "addDeviceModal"
        }
      }),
      !_vm.loading
        ? _c(
            "v-row",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.$store.getters.isAccountLocked
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-3 centered",
                      attrs: {
                        cols: 12,
                        md: 10,
                        "offset-md": 1,
                        lg: 8,
                        "offset-lg": 2
                      }
                    },
                    [
                      _c(
                        "p",
                        { staticStyle: { "font-style": "italic" } },
                        [
                          _vm._v(
                            "Device settings cannot be changed because the account is locked.  Go to the "
                          ),
                          _c("router-link", { attrs: { to: "account" } }, [
                            _vm._v("account")
                          ]),
                          _vm._v(" page to unlock your account.")
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.$store.getters.insufficientSubscription
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-3 centered",
                      attrs: {
                        cols: 12,
                        md: 10,
                        "offset-md": 1,
                        lg: 8,
                        "offset-lg": 2
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "pr-1", attrs: { color: "accent" } },
                            [_vm._v("warning")]
                          ),
                          _c("span", [
                            _vm._v("You have too many "),
                            _c("b", [_vm._v("monitored")]),
                            _vm._v(" devices!  ")
                          ]),
                          _c(
                            "v-icon",
                            { staticClass: "pl-1", attrs: { color: "accent" } },
                            [_vm._v("warning")]
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          "\n          Please upgrade your subscription or disable monitoring.  "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { color: "accent" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push("account")
                              }
                            }
                          },
                          [_vm._v("Upgrade now!")]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: 12,
                    md: 10,
                    "offset-md": 1,
                    lg: 8,
                    "offset-lg": 2
                  }
                },
                [
                  [
                    _vm.saveSuccessfulMessage
                      ? _c("div", { staticClass: "text-center pa-3" }, [
                          _vm._v("Save successful!"),
                          _c("br"),
                          _c("b", [
                            _vm._v(
                              "Device settings can take a few minutes to sync on monitored devices."
                            )
                          ]),
                          _c("br"),
                          _vm._v(
                            "To force an immediate update, ensure you have an internet connection and then restart your device."
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          persistent: "",
                          fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                          "max-width": "600px"
                        },
                        model: {
                          value: _vm.showBlockUninstallWarning,
                          callback: function($$v) {
                            _vm.showBlockUninstallWarning = $$v
                          },
                          expression: "showBlockUninstallWarning"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("span", [_vm._v("Block Tamper Warning")]),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: _vm.blockUninstallWarningCancel
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("close")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _c("p", [
                                  _vm._v("\n                  We do "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-decoration-underline"
                                    },
                                    [_vm._v("not")]
                                  ),
                                  _vm._v(
                                    " recommend the Block Tamper feature. " +
                                      _vm._s(_vm.appName) +
                                      " will report if the app is tampered with regardless of whether Block Tamper is enabled.  It is better for adults and children to learn to use the internet responsibly than to take away that choice.  Block Tamper can make removing " +
                                      _vm._s(_vm.appName) +
                                      " more difficult.\n                "
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "\n                  Block Tamper can only be used on devices "
                                  ),
                                  _c("b", [_vm._v("YOU")]),
                                  _vm._v(
                                    " own.\n                  It is against " +
                                      _vm._s(_vm.appName) +
                                      "'s "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://truple.io/termsofuse.html",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("Terms of Use")]
                                  ),
                                  _vm._v(
                                    " to use " +
                                      _vm._s(_vm.appName) +
                                      " to monitor a device without the device owners knowledge and consent.\n                "
                                  )
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass: "font-weight-bold text-body-1"
                                  },
                                  [
                                    _vm._v(
                                      "In order to enable Block Tamper for this device, you must agree that the following is true:"
                                    )
                                  ]
                                ),
                                _c("v-checkbox", {
                                  staticClass: "mt-0 pt-0",
                                  attrs: {
                                    label: "I own this device",
                                    dense: ""
                                  },
                                  model: {
                                    value:
                                      _vm.blockUninstallAgreementChecks.own,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.blockUninstallAgreementChecks,
                                        "own",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "blockUninstallAgreementChecks.own"
                                  }
                                }),
                                _c("v-checkbox", {
                                  staticClass: "mt-0 pt-0",
                                  attrs: {
                                    label:
                                      "This device will be used primarily by me or a non-adult child",
                                    dense: ""
                                  },
                                  model: {
                                    value:
                                      _vm.blockUninstallAgreementChecks.used,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.blockUninstallAgreementChecks,
                                        "used",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "blockUninstallAgreementChecks.used"
                                  }
                                }),
                                _c("v-checkbox", {
                                  staticClass: "mt-0 pt-0",
                                  attrs: {
                                    label:
                                      "I understand Block Tamper can make removing " +
                                      _vm.appName +
                                      " difficult",
                                    dense: ""
                                  },
                                  model: {
                                    value:
                                      _vm.blockUninstallAgreementChecks
                                        .difficult,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.blockUninstallAgreementChecks,
                                        "difficult",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "blockUninstallAgreementChecks.difficult"
                                  }
                                }),
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-5",
                                    attrs: { type: "warning" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Failure to comply with these requirements could result in "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-decoration-underline"
                                      },
                                      [
                                        _vm._v(
                                          "account termination and banning"
                                        )
                                      ]
                                    ),
                                    _vm._v(".\n                ")
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    on: {
                                      click: _vm.blockUninstallWarningCancel
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      disabled:
                                        !_vm.blockUninstallAgreementChecks
                                          .own ||
                                        !_vm.blockUninstallAgreementChecks
                                          .used ||
                                        !_vm.blockUninstallAgreementChecks
                                          .difficult
                                    },
                                    on: {
                                      click: _vm.blockUninstallWarningAgree
                                    }
                                  },
                                  [_vm._v("I Agree")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.devices.length > 0
                      ? _c(
                          "v-expansion-panels",
                          { attrs: { popout: true } },
                          _vm._l(_vm.devices, function(device, deviceIndex) {
                            return _c(
                              "v-expansion-panel",
                              { key: device.deviceId },
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  {
                                    staticStyle: {
                                      "background-color": "#00796B",
                                      "border-top": "1px solid white"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "accent" } },
                                                [
                                                  _vm._v(
                                                    "$vuetify.icons.expand"
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-overflow": "clip" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "white--text title" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "accent--text" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.currentDevice ===
                                                        device.deviceId
                                                        ? "[this device]"
                                                        : ""
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "pr-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(device.deviceName)
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "caption white--text"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                "(" +
                                                  (device.deviceMarketName
                                                    ? device.deviceMarketName +
                                                      ", "
                                                    : "") +
                                                  device.os +
                                                  ")"
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "pt-3 pb-3",
                                        attrs: { row: "", wrap: "" }
                                      },
                                      [
                                        device.userAgent === "doh"
                                          ? [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pr-5 pl-2 pb-1 pt-1",
                                                  attrs: {
                                                    cols: 12,
                                                    md: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        label:
                                                          "Selected filter",
                                                        column: ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetSdnsStamp(
                                                            device
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: device.filterId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            device,
                                                            "filterId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "device.filterId"
                                                      }
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.filters,
                                                        function(filter) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                key: filter.id
                                                              },
                                                              [
                                                                _c("v-radio", {
                                                                  key:
                                                                    filter.id,
                                                                  staticClass:
                                                                    "mb-0",
                                                                  attrs: {
                                                                    label:
                                                                      filter.name,
                                                                    value:
                                                                      filter.id
                                                                  }
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "caption pl-8 mb-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          filter.description
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pr-5 pl-2 pb-1 pt-1",
                                                  attrs: {
                                                    cols: 12,
                                                    md: 6,
                                                    xl: 4
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      device.hasChanged
                                                        ? _c(
                                                            "v-alert",
                                                            {
                                                              attrs: {
                                                                type: "warning",
                                                                outlined: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          You must re-setup the device for changes to take effect.\n                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "float-right mb-1",
                                                      attrs: {
                                                        outlined: "",
                                                        color: "primary",
                                                        href:
                                                          device.instructionsLink,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "pr-1" },
                                                        [_vm._v("get_app")]
                                                      ),
                                                      _vm._v(
                                                        "\n                        View setup instructions\n                      "
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                      attrs: {
                                                        outlined: "",
                                                        color: "primary",
                                                        href: device.mailToLink,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "pr-1" },
                                                        [_vm._v("email")]
                                                      ),
                                                      _vm._v(
                                                        "\n                        Email instructions\n                      "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    "prepend-icon":
                                                      "mobile_screen_share",
                                                    disabled:
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    label:
                                                      "Screenshot Monitoring " +
                                                      (device.takeSnapshots
                                                        ? "Enabled"
                                                        : "Disabled"),
                                                    hint:
                                                      "Enables or disables screenshot monitoring.  If you disable then re-enable, you must restart the device then open the app to cause monitoring to start again."
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value: device.takeSnapshots,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "takeSnapshots",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.takeSnapshots"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                        device.os === "android" ||
                                        device.os === "kindle"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items:
                                                      device.os === "android"
                                                        ? [
                                                            "OWNER",
                                                            "ADMIN",
                                                            "USER"
                                                          ]
                                                        : ["USER"],
                                                    "prepend-icon": "gavel",
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    label: "Device Type",
                                                    hint:
                                                      "User devices can only be monitored.  Admin devices can be monitored and view screenshots.  Owner devices can be monitored, view screenshots, change device & account settings.",
                                                    disabled:
                                                      device.os !== "android" ||
                                                      _vm.$store.getters
                                                        .isAccountLocked
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value: device.deviceType,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "deviceType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.deviceType"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.os === "android" ||
                                        device.os === "kindle"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    "prepend-icon": "security",
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    label:
                                                      "Device Admin " +
                                                      (device.adminDevice
                                                        ? "Enabled"
                                                        : "Disabled"),
                                                    hint:
                                                      "Device admin apps cannot be shutdown.  Also sends emails upon app Uninstall."
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value: device.adminDevice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "adminDevice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.adminDevice"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        (device.os === "android" &&
                                          (device.blockUninstall ||
                                            (device.installingPackageName &&
                                              device.installingPackageName !==
                                                "com.android.vending" &&
                                              device.installingPackageName !==
                                                "com.google.android.feedback"))) ||
                                        device.os === "kindle" ||
                                        device.os === "windows" ||
                                        device.os === "mac" ||
                                        device.os === "linux"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked ||
                                                      (!device.blockUninstall &&
                                                        device.os ===
                                                          "android" &&
                                                        (!device.installingPackageName ||
                                                          device.installingPackageName ===
                                                            "com.android.vending" ||
                                                          device.installingPackageName ===
                                                            "com.google.android.feedback")),
                                                    "prepend-icon": "lock",
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    label:
                                                      "Block Tamper " +
                                                      (device.blockUninstall
                                                        ? "Enabled"
                                                        : "Disabled"),
                                                    hint:
                                                      "Blocks tamper attempts. Due to developer policies, this feature is not available for apps installed through Google Play. Contact support@truple.io with any questions."
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.blockUninstallChange(
                                                        deviceIndex
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      device.blockUninstall,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "blockUninstall",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.blockUninstall"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.os === "android" ||
                                        device.os === "kindle"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    "prepend-icon": "wifi",
                                                    label:
                                                      "Wifi Preferred " +
                                                      (device.wifiUploadOnly
                                                        ? "Enabled"
                                                        : "Disabled"),
                                                    hint:
                                                      "When enabled, the device waits up to 12 hours for a wifi connection to upload screenshots.  After 12 hours screenshots will be uploaded via mobile data.  When disabled, device uploads screenshots immediately whether using a wifi connection or not."
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value:
                                                      device.wifiUploadOnly,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "wifiUploadOnly",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.wifiUploadOnly"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.os === "android" ||
                                        device.os === "windows" ||
                                        device.os === "mac"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    "prepend-icon":
                                                      "short_text",
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    label:
                                                      "Redact text (black out words) " +
                                                      (device.redactText
                                                        ? "Enabled"
                                                        : "Disabled"),
                                                    hint:
                                                      "When enabled, the screenshots will have most words (text messages, email, web pages, etc.) blacked out.  When disabled, the screenshot will show all words.  Text redaction is only available on Android, Windows 10, and Mac OS v10.11+"
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value: device.redactText,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "redactText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.redactText"
                                                  }
                                                }),
                                                device.os === "windows"
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "pl-8" },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "font-italic caption",
                                                            attrs: {
                                                              href:
                                                                "https://" +
                                                                _vm.supportDomain +
                                                                "/articles/windows/windows-text-redaction-non-english",
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "(requires English language installed)"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.userAgent !== "doh"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-slider", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    "prepend-icon": "blur_on",
                                                    "persistent-hint": "",
                                                    "track-color": "light-gray",
                                                    color: "accent",
                                                    min: 0,
                                                    max: 5,
                                                    label: "Blur Screenshots",
                                                    hint:
                                                      "Blur Screenshots: " +
                                                      _vm.blurText(
                                                        device.blurImages
                                                      ) +
                                                      ".  When enabled, the screenshots will be blurred.  Blurring screenshots can protect those viewing your reports from graphic details in images as well as your own privacy.  When disabled, the screenshot will show all details."
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value: device.blurImages,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "blurImages",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.blurImages"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.userAgent !== "doh"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-slider", {
                                                  staticStyle: {
                                                    "margin-bottom": "-18px"
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    "prepend-icon": "replay",
                                                    "track-color": "light-gray",
                                                    color: "accent",
                                                    min: 0.5,
                                                    step: 0.1,
                                                    max: 5.0,
                                                    label: "Screenshot Interval"
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value:
                                                      device.minutesPerScreenshot,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "minutesPerScreenshot",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.minutesPerScreenshot"
                                                  }
                                                }),
                                                device.minutesPerScreenshot <
                                                1.0
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "pl-8" },
                                                      [
                                                        _c(
                                                          "v-alert",
                                                          {
                                                            attrs: {
                                                              value: true
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "headline error--text"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "warning"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  "\n                          WARNING\n                          "
                                                                ),
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "warning"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "caption error--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.appName
                                                                    ) +
                                                                    " can only load ~1200 screenshots per device, per 24 hour period.  If more than 1200 screenshots are captured,\n                          " +
                                                                    _vm._s(
                                                                      _vm.appName
                                                                    ) +
                                                                    " may fail to load them and you won't be able to view any of the screenshots.\n                          Average frequencies below 1 minute will drastically increase battery consumption & data usage.\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pl-8 caption font-italic",
                                                    staticStyle: {
                                                      color: "rgba(0,0,0,.54)"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Average Interval:"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.showTime(
                                                            device.minutesPerScreenshot *
                                                              60.0 *
                                                              1000.0
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Minimum Interval:"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.intervalMin()
                                                        )
                                                      }
                                                    }),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Maximum Interval:"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.intervalMax(
                                                            device.minutesPerScreenshot *
                                                              60.0 *
                                                              1000.0
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _c("br"),
                                                    _c("br"),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Note: Each interval is a uniformly distributed random value between the minimum interval and the maximum interval.  The screenshot timer is paused when the screen turns off or the phone goes idle."
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.os === "android" ||
                                        device.os === "kindle"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline float-left"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "pause_circle_outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline float-left ml-2"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "v-label theme--light mb-2 mt-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Auto Paused / Whitelisted Apps"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.getEnabledApaApps(
                                                        device.autoPauseApps
                                                      ),
                                                      function(eapa) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              device.deviceId +
                                                              "." +
                                                              eapa.appId,
                                                            staticClass:
                                                              "caption text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  eapa.appName +
                                                                    (eapa.killApp
                                                                      ? " (restart on pause enabled)"
                                                                      : "")
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center mt-1 mb-1",
                                                        staticStyle: {
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-dialog",
                                                          {
                                                            attrs: {
                                                              "max-width":
                                                                "900",
                                                              fullscreen:
                                                                _vm.$store.state
                                                                  .isMobile
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              _vm
                                                                                .$store
                                                                                .getters
                                                                                .isAccountLocked ||
                                                                              !device.takeSnapshots,
                                                                            color:
                                                                              "primary",
                                                                            label:
                                                                              "Modify Auto Pause",
                                                                            outlined:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.openAutoPause.apply(
                                                                                null,
                                                                                arguments
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Modify\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.showAutoPausedDialog,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.showAutoPausedDialog = $$v
                                                              },
                                                              expression:
                                                                "showAutoPausedDialog"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              [
                                                                _c(
                                                                  "v-card-title",
                                                                  {
                                                                    staticClass:
                                                                      "headline primary white--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              Auto Paused / Whitelisted Apps\n                              "
                                                                    ),
                                                                    _c(
                                                                      "v-spacer"
                                                                    ),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          dark:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.showAutoPausedDialog = false
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "close"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "mt-5"
                                                                  },
                                                                  _vm._l(
                                                                    device.autoPauseApps,
                                                                    function(
                                                                      apa
                                                                    ) {
                                                                      return _c(
                                                                        "v-row",
                                                                        {
                                                                          key:
                                                                            device.deviceId +
                                                                            "." +
                                                                            apa.appId +
                                                                            ".custom"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols: 12,
                                                                                md: 4,
                                                                                lg: 6
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-primary title"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      apa.appName
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "caption font-italic"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      "(" +
                                                                                        apa.appId +
                                                                                        ")"
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols: 12,
                                                                                md: 8,
                                                                                lg: 6
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-checkbox",
                                                                                {
                                                                                  staticClass:
                                                                                    "ma-0 pa-0",
                                                                                  attrs: {
                                                                                    label:
                                                                                      "Enabled",
                                                                                    hint:
                                                                                      "Enable or disable auto pause for this app",
                                                                                    "persistent-hint":
                                                                                      "",
                                                                                    dense:
                                                                                      ""
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      apa.autoPause,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        apa,
                                                                                        "autoPause",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "apa.autoPause"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "v-checkbox",
                                                                                {
                                                                                  directives: [
                                                                                    {
                                                                                      name:
                                                                                        "show",
                                                                                      rawName:
                                                                                        "v-show",
                                                                                      value:
                                                                                        apa.autoPause,
                                                                                      expression:
                                                                                        "apa.autoPause"
                                                                                    }
                                                                                  ],
                                                                                  staticClass:
                                                                                    "ma-0 pa-0",
                                                                                  attrs: {
                                                                                    label:
                                                                                      "(Not Recommended) Restart on Pause",
                                                                                    hint:
                                                                                      "Some apps (like hulu) need to have the " +
                                                                                      _vm.appName +
                                                                                      " app restarted before they'll allow you to view the content.  If you find viewing the content doesn't work without this enabled, enable it and it should work.  We do not recommend enabling this unless you thoroughly test it on your device to ensure it doesn't open up loopholes, as it may depending on the device you have.",
                                                                                    "persistent-hint":
                                                                                      "",
                                                                                    dense:
                                                                                      "",
                                                                                    disabled: !apa.autoPause
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      apa.killApp,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        apa,
                                                                                        "killApp",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "apa.killApp"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols: 12,
                                                                                md: 12
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-divider",
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-2 mb-2"
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-card-actions",
                                                                  {
                                                                    staticClass:
                                                                      "justify-center"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            _vm.addCustomAPA = true
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                Add Custom\n                              "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-dialog",
                                                                      {
                                                                        attrs: {
                                                                          "max-width":
                                                                            "400"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return undefined
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                        model: {
                                                                          value:
                                                                            _vm.addCustomAPA,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.addCustomAPA = $$v
                                                                          },
                                                                          expression:
                                                                            "addCustomAPA"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          [
                                                                            _c(
                                                                              "v-card-text",
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      placeholder:
                                                                                        "Netflix",
                                                                                      hint:
                                                                                        "App name",
                                                                                      primary:
                                                                                        "",
                                                                                      autofocus:
                                                                                        "",
                                                                                      "persistent-hint":
                                                                                        ""
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .customApa
                                                                                          .appName,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.customApa,
                                                                                          "appName",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "customApa.appName"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    attrs: {
                                                                                      placeholder:
                                                                                        "com.netflix.mediaclient",
                                                                                      primary:
                                                                                        "",
                                                                                      hint:
                                                                                        "App Id",
                                                                                      "persistent-hint":
                                                                                        ""
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .customApa
                                                                                          .appId,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.customApa,
                                                                                          "appId",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "customApa.appId"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "caption mt-4"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                      Go to "
                                                                                    ),
                                                                                    _c(
                                                                                      "a",
                                                                                      {
                                                                                        attrs: {
                                                                                          href:
                                                                                            "https://play.google.com/store/apps",
                                                                                          target:
                                                                                            "_blank"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "play.google.com/store/apps"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " to search for the app id."
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "font-italic"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "For example, Netflix's app id is "
                                                                                        ),
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "com.netflix.mediaclient"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ".  Netflix's Google Play url is: play.google.com/store/apps/details?id="
                                                                                        ),
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "com.netflix.mediaclient"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "."
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs: {
                                                                                      primary:
                                                                                        "",
                                                                                      label:
                                                                                        "Enabled"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .customApa
                                                                                          .autoPause,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.customApa,
                                                                                          "autoPause",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "customApa.autoPause"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs: {
                                                                                      primary:
                                                                                        "",
                                                                                      label:
                                                                                        "Restart App on Pause",
                                                                                      hint:
                                                                                        "Do not enable this unless you still get an error message even after the screencast permission has disappeared from your notification bar."
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .customApa
                                                                                          .killApp,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.customApa,
                                                                                          "killApp",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "customApa.killApp"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-card-actions",
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.addCustomAPA = false
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                      close\n                                    "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-spacer"
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      primary:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.addCustomAPAToDevice(
                                                                                          device
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                      Add\n                                    "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption font-italic theme--light v-messages"
                                                      },
                                                      [
                                                        _vm._v("Click "),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "https://" +
                                                                _vm.supportDomain +
                                                                "/articles/android/screenshot-accountability-streaming",
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [_vm._v("here")]
                                                        ),
                                                        _vm._v(
                                                          " for more info on auto pause."
                                                        )
                                                      ]
                                                    ),
                                                    _vm.apaOpened
                                                      ? _c(
                                                          "v-alert",
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              type: "warning"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "You must save your changes and then restart the device for changes to auto-pause apps to take effect."
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        device.userAgent !== "doh"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      _vm.$store.getters
                                                        .isAccountLocked,
                                                    "prepend-icon": "report",
                                                    color: "primary",
                                                    "persistent-hint": "",
                                                    label:
                                                      "Rate screenshots " +
                                                      (device.rateScreenshots
                                                        ? "Enabled"
                                                        : "Disabled"),
                                                    hint:
                                                      "When enabled, screenshots will be analyzed for risk.  Enabling will increase cpu, ram, and battery usage and may slow your device down."
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value:
                                                      device.rateScreenshots,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "rateScreenshots",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.rateScreenshots"
                                                  }
                                                }),
                                                _c(
                                                  "v-fade-transition",
                                                  [
                                                    device.takeSnapshots &&
                                                    device.rateScreenshots
                                                      ? _c("v-slider", {
                                                          attrs: {
                                                            disabled:
                                                              !device.takeSnapshots ||
                                                              !device.rateScreenshots ||
                                                              _vm.$store.getters
                                                                .isAccountLocked,
                                                            "prepend-icon":
                                                              "slow_motion_video",
                                                            "persistent-hint":
                                                              "",
                                                            "track-color":
                                                              "light-gray",
                                                            color: "accent",
                                                            min: 0,
                                                            max: 4,
                                                            label:
                                                              "Algorithm Sensitivity",
                                                            hint:
                                                              "Sensitivity: " +
                                                              _vm.sensitivityText(
                                                                device.sensitivity
                                                              ) +
                                                              ".  Decreasing sensitivity will cause fewer screenshots to be marked high risk due to content analysis, but may cause the algorithm to miss some high risk screenshots."
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.markDirty
                                                          },
                                                          model: {
                                                            value:
                                                              device.sensitivity,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                device,
                                                                "sensitivity",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "device.sensitivity"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        device.os === "android" ||
                                        device.os === "kindle"
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pr-5 pl-2 pb-1 pt-1",
                                                attrs: {
                                                  cols: 12,
                                                  md: 6,
                                                  lg: 4
                                                }
                                              },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !device.takeSnapshots ||
                                                      !_vm.$store.getters
                                                        .isE2EEEnabled,
                                                    "persistent-hint": "",
                                                    color: "primary",
                                                    label:
                                                      "Allow screen capture of financial and medical apps",
                                                    hint:
                                                      _vm.appName +
                                                      " typically prevents screenshots from being captured of financial and medical apps & websites.  However on some android devices, some of the apps (but not websites) could potentially be used to bypass " +
                                                      _vm.appName +
                                                      ".  Enable this to allow capturing screenshots of those apps.  " +
                                                      _vm.appName +
                                                      " still won't capture banking websites.  End-to-end encryption is required in order to enable."
                                                  },
                                                  on: { change: _vm.markDirty },
                                                  model: {
                                                    value:
                                                      device.allowFinancialAndMedicalApps,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        device,
                                                        "allowFinancialAndMedicalApps",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "device.allowFinancialAndMedicalApps"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pr-5 pl-2 pb-1 pt-1",
                                            attrs: { cols: 12, md: 6, lg: 4 }
                                          },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                disabled:
                                                  _vm.$store.getters
                                                    .isAccountLocked,
                                                "prepend-icon": "delete",
                                                color: "primary",
                                                "persistent-hint": "",
                                                label: "Delete",
                                                hint:
                                                  "Devices may not automatically delete when the " +
                                                  _vm.appName +
                                                  " app is uninstalled.  You'll need to delete them here once you've uninstalled them."
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.deleteClicked(
                                                    $event,
                                                    device
                                                  )
                                                }
                                              },
                                              model: {
                                                value: device.delete,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    device,
                                                    "delete",
                                                    $$v
                                                  )
                                                },
                                                expression: "device.delete"
                                              }
                                            }),
                                            device.delete &&
                                            device.userAgent === "doh"
                                              ? _c(
                                                  "v-alert",
                                                  {
                                                    staticClass: "mt-2 mb-0",
                                                    attrs: {
                                                      type: "warning",
                                                      outlined: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      If you delete the device, without first removing " +
                                                        _vm._s(_vm.appName) +
                                                        " from the device, the internet will stop working on the iPhone/iPad!\n                      "
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "https://blog.truple.io/2020/09/21/remove-doh-from-ios.html",
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "View instructions for removing\n                        " +
                                                            _vm._s(
                                                              _vm.appName
                                                            ) +
                                                            " from the device."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pr-5 pl-2 pb-1 pt-1",
                                            attrs: { cols: 12 }
                                          },
                                          [
                                            !device.showDeviceId
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "font-italic font-weight-light float-end caption text-capitalize",
                                                    attrs: {
                                                      text: "",
                                                      disabled:
                                                        device.showDeviceId
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        device.showDeviceId = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Show Device Id\n                    "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-italic font-weight-light float-end caption pt-5"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          "Device Id: " +
                                                            device.deviceId
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "text-center pa-3 mx-3",
                            staticStyle: { border: "dashed lightgray 1px" }
                          },
                          [
                            _vm._v(
                              "\n            You have no devices on your account.  To get started "
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.addDeviceButton()
                                  }
                                }
                              },
                              [_vm._v("add a device")]
                            ),
                            _vm._v(".\n          ")
                          ]
                        ),
                    _c(
                      "v-container",
                      [
                        _vm.dohDeletedDevices.length > 0
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-2 pr-2 mt-2 d-block",
                                    attrs: { cols: 12 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "float-right" },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "Have you removed " +
                                              _vm._s(_vm.appName) +
                                              " from these devices?"
                                          )
                                        ]),
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v(
                                            "If you fail to remove " +
                                              _vm._s(_vm.appName) +
                                              " from the device, deleting the device will cause the device to lose it's internet connection."
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "caption text-right" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://blog.truple.io/2020/09/21/remove-doh-from-ios.html",
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "See how to remove\n                      " +
                                                    _vm._s(_vm.appName)
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._l(_vm.dohDeletedDevices, function(
                                          d
                                        ) {
                                          return _c(
                                            "v-card",
                                            {
                                              key:
                                                d.deviceId + "_delete_verify_2",
                                              staticStyle: { height: "53px" },
                                              attrs: { outlined: "" }
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "float-left pr-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(d.deviceName)
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-checkbox", {
                                                    staticClass:
                                                      "float-right ma-0 pa-0",
                                                    attrs: { dense: "" },
                                                    model: {
                                                      value: d.hasRemovedDoh,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          d,
                                                          "hasRemovedDoh",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "d.hasRemovedDoh"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "float-right pr-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Check to verify removed"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-4 pr-4",
                                attrs: { cols: 12, "d-flex": "" }
                              },
                              [
                                !_vm.cantSave
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          outlined: "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.addDeviceButton()
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("add")
                                        ]),
                                        _vm._v(
                                          "\n                  Add a device\n                "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "float-right" },
                                  [
                                    _vm.cantSave
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "caption error--text text-right mr-2 float-left"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Please verify you've removed " +
                                                _vm._s(_vm.appName) +
                                                " from the iOS devices before saving.\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "float-right",
                                        attrs: {
                                          outlined: "",
                                          color: "primary",
                                          disabled:
                                            _vm.$store.getters
                                              .isAccountLocked || _vm.cantSave
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveDeviceSettings()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Save\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-2" },
                          [
                            _c("v-col", { attrs: { cols: 12 } }, [
                              _c(
                                "span",
                                {
                                  staticClass: "caption",
                                  staticStyle: { "font-style": "italic" }
                                },
                                [
                                  _vm._v(
                                    "Emails are sent to the account owner and everyone who receives reports when device settings changes are made."
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 750, bottom: true },
          model: {
            value: _vm.showMessage,
            callback: function($$v) {
              _vm.showMessage = $$v
            },
            expression: "showMessage"
          }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.message) + "\n      "),
          _c(
            "v-btn",
            {
              staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
              attrs: { icon: "", small: "", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.showMessage = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350px" },
          model: {
            value: _vm.iosDeleteAlertDialog,
            callback: function($$v) {
              _vm.iosDeleteAlertDialog = $$v
            },
            expression: "iosDeleteAlertDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Delete iOS Device")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.iosDeleteAlertDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v("\n          You "),
                _c("b", [_vm._v("MUST")]),
                _vm._v(
                  " remove " +
                    _vm._s(_vm.appName) +
                    " from the device prior to deleting, or else the device will lose it's internet connection!\n        "
                )
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-block" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", block: "" },
                      on: { click: _vm.openiOSRemoveInstructions }
                    },
                    [_vm._v("View instructions")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showDirtyWarning,
            callback: function($$v) {
              _vm.showDirtyWarning = $$v
            },
            expression: "showDirtyWarning"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n          Unsaved Changes\n        ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n          You have unsaved changes.  Please save them if you wish to keep them.\n        "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showDirtyWarning = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }