<template>
  <v-container>
    <loading v-if="loading"></loading>

    <add-device-dialog v-model="addDeviceModal" @on-close="loadDevices" />

    <v-row v-if="!loading" row wrap>

      <v-col :cols="12" :md="10" :offset-md="1" :lg="8" :offset-lg="2" v-if="$store.getters.isAccountLocked" class="pa-3 centered">
        <p style="font-style: italic">Device settings cannot be changed because the account is locked.  Go to the <router-link to="account">account</router-link> page to unlock your account.</p>
      </v-col>

      <v-col :cols="12" :md="10" :offset-md="1" :lg="8" :offset-lg="2" v-if="$store.getters.insufficientSubscription" class="pa-3 centered">
        <!--<v-alert :value="$store.getters.insufficientSubscription">-->
        <div>
          <v-icon color="accent" class="pr-1">warning</v-icon> <span>You have too many <b>monitored</b> devices!  </span> <v-icon color="accent" class="pl-1">warning</v-icon>
        </div>
        <div>
          Please upgrade your subscription or disable monitoring.  <a color="accent" @click="$router.push('account')">Upgrade now!</a>
        </div>
        <!--</v-alert>-->
      </v-col>
      <v-col :cols="12" :md="10" :offset-md="1" :lg="8" :offset-lg="2">

        <template class="devices">

          <div v-if="saveSuccessfulMessage" class="text-center pa-3">Save successful!<br />
            <b>Device settings can take a few minutes to sync on monitored devices.</b><br />To force an immediate update, ensure you have an internet connection and then restart your device.</div>

          <v-dialog v-model="showBlockUninstallWarning" persistent :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600px">
            <v-card>
              <v-card-title>
                <span>Block Tamper Warning</span><v-spacer /><v-btn icon @click="blockUninstallWarningCancel"><v-icon>close</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <p>
                  We do <span class="text-decoration-underline">not</span> recommend the Block Tamper feature. {{ appName }} will report if the app is tampered with regardless of whether Block Tamper is enabled.  It is better for adults and children to learn to use the internet responsibly than to take away that choice.  Block Tamper can make removing {{appName}} more difficult.
                </p>
                <p>
                  Block Tamper can only be used on devices <b>YOU</b> own.
                  It is against {{ appName }}'s <a href="https://truple.io/termsofuse.html" target="_blank">Terms of Use</a> to use {{ appName }} to monitor a device without the device owners knowledge and consent.
                </p>
                <p class="font-weight-bold text-body-1">In order to enable Block Tamper for this device, you must agree that the following is true:</p>
                <v-checkbox v-model="blockUninstallAgreementChecks.own" class="mt-0 pt-0" label="I own this device" dense />
                <v-checkbox v-model="blockUninstallAgreementChecks.used" class="mt-0 pt-0" label="This device will be used primarily by me or a non-adult child" dense />
                <v-checkbox v-model="blockUninstallAgreementChecks.difficult" class="mt-0 pt-0" :label="`I understand Block Tamper can make removing ${appName} difficult`" dense />
                <v-alert type="warning" class="mt-5">
                  Failure to comply with these requirements could result in <span class="text-decoration-underline">account termination and banning</span>.
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="blockUninstallWarningCancel">Cancel</v-btn>
                <v-spacer />
                <v-btn @click="blockUninstallWarningAgree" color="primary" :disabled="!blockUninstallAgreementChecks.own || !blockUninstallAgreementChecks.used || !blockUninstallAgreementChecks.difficult">I Agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-expansion-panels :popout="true" v-if="devices.length > 0">
            <v-expansion-panel v-for="(device, deviceIndex) in devices" :key="device.deviceId">
              <v-expansion-panel-header style="background-color: #00796B; border-top: 1px solid white;">
                <div style="text-overflow: clip;">
                  <span class="white--text title">
                    <span class="accent--text">
                      {{(currentDevice === device.deviceId ? '[this device]' : '')}}
                    </span>
                    <span class="pr-1">{{device.deviceName }}</span>
                  </span><span class="caption white--text">{{ '(' + (device.deviceMarketName ? device.deviceMarketName + ', ' : '') + device.os + ')' }}</span>
                </div>
                <template v-slot:actions>
                  <v-icon color="accent">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row row wrap class="pt-3 pb-3">
                  <template v-if="device.userAgent === 'doh'">
                    <v-col :cols="12" :md="6" :xl="4" class="pr-5 pl-2 pb-1 pt-1">
                      <v-radio-group
                        @change="resetSdnsStamp(device)"
                        color="primary"
                        v-model="device.filterId"
                        label="Selected filter"
                        column>
                        <template
                          v-for="filter in filters"
                        >
                          <div :key="filter.id">
                            <v-radio
                              class="mb-0"
                              :key="filter.id"
                              :label="filter.name"
                              :value="filter.id"
                            >
                            </v-radio>
                            <div class="caption pl-8 mb-2">
                              {{filter.description}}
                            </div>
                          </div>

                        </template>
                      </v-radio-group>
                    </v-col>
                    <v-col :cols="12" :md="6" :xl="4" class="pr-5 pl-2 pb-1 pt-1">

                      <div>
                        <v-alert v-if="device.hasChanged" type="warning" outlined>
                          You must re-setup the device for changes to take effect.
                        </v-alert>
                      </div>

                      <v-btn outlined
                             class="float-right mb-1"
                             color="primary"
                             :href="device.instructionsLink"
                             target="_blank"
                      >
                        <v-icon class="pr-1">get_app</v-icon>
                        View setup instructions
                      </v-btn>

                      <v-btn outlined
                             class="float-right"
                             color="primary"
                             :href="device.mailToLink"
                             target="_blank"
                             >
                        <v-icon class="pr-1">email</v-icon>
                        Email instructions
                      </v-btn>

<!--                      <div>-->
<!--                        <v-textarea-->
<!--                          @click="copyToClipboard"-->
<!--                          class="pb-2 force-pointer"-->
<!--                          label="iOS 10-13 DNSCloak Config (click to copy)"-->
<!--                          readonly-->
<!--                          dense-->
<!--                          v-model="device.dnsCloakConfig"-->
<!--                          :auto-grow="false"-->
<!--                          filled-->
<!--                          outlined-->
<!--                          no-resize-->
<!--                          :hide-details="!device.hasChanged"-->
<!--                          :error-messages="device.hasChanged ? ['You must update the DNSCloak config within the DNSCloak app each time you make a change!'] : []"-->
<!--                        >-->
<!--                        </v-textarea>-->
<!--                        &lt;!&ndash;                      <v-btn outlined&ndash;&gt;-->
<!--                        &lt;!&ndash;                             class="float-right"&ndash;&gt;-->
<!--                        &lt;!&ndash;                             color="primary"&ndash;&gt;-->
<!--                        &lt;!&ndash;                             v-clipboard:copy="device.dnsCloakConfig"&ndash;&gt;-->
<!--                        &lt;!&ndash;                             v-clipboard:success="copyToClipboard">&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <v-icon class="pr-1">file_copy</v-icon>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        Copy DNSCloak Config&ndash;&gt;-->
<!--                        &lt;!&ndash;                      </v-btn>&ndash;&gt;-->
<!--                      </div>-->

                    </v-col>
                  </template>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-else>
                    <v-switch
                      prepend-icon="mobile_screen_share"
                              :disabled="$store.getters.isAccountLocked"
                              @change="markDirty"
                              color="primary" persistent-hint
                              v-model="device.takeSnapshots"
                              :label="`Screenshot Monitoring ${device.takeSnapshots ? 'Enabled' : 'Disabled'}`"
                              hint="Enables or disables screenshot monitoring.  If you disable then re-enable, you must restart the device then open the app to cause monitoring to start again."></v-switch>

                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'android' || device.os === 'kindle'">
                    <v-select
                            :items="device.os === 'android' ? ['OWNER', 'ADMIN', 'USER'] : ['USER']"
                            prepend-icon="gavel"
                            color="primary" persistent-hint
                            @change="markDirty"
                            v-model="device.deviceType"
                            label="Device Type"
                            hint="User devices can only be monitored.  Admin devices can be monitored and view screenshots.  Owner devices can be monitored, view screenshots, change device & account settings."
                            :disabled="device.os !== 'android' || $store.getters.isAccountLocked"
                    ></v-select>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'android' || device.os === 'kindle'">
                    <v-switch :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"
                              prepend-icon="security"
                              color="primary" persistent-hint
                              @change="markDirty"
                              v-model="device.adminDevice"
                              :label="`Device Admin ${device.adminDevice ? 'Enabled' : 'Disabled'}`"
                              :hint="`Device admin apps cannot be shutdown.  Also sends emails upon app Uninstall.`"></v-switch>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="(device.os === 'android' && (device.blockUninstall || (device.installingPackageName && device.installingPackageName !== 'com.android.vending' && device.installingPackageName !== 'com.google.android.feedback')))
                   || device.os === 'kindle'
                    || device.os === 'windows'
                     || device.os === 'mac'
                      || device.os === 'linux'">
                    <v-switch :disabled="!device.takeSnapshots ||
                     $store.getters.isAccountLocked ||
                      (!device.blockUninstall && device.os === 'android' &&
                       (!device.installingPackageName || device.installingPackageName === 'com.android.vending' || device.installingPackageName === 'com.google.android.feedback'))"
                              prepend-icon="lock"
                              color="primary" persistent-hint
                              @change="blockUninstallChange(deviceIndex)"
                              v-model="device.blockUninstall"
                              :label="`Block Tamper ${device.blockUninstall ? 'Enabled' : 'Disabled'}`"
                              :hint="`Blocks tamper attempts. Due to developer policies, this feature is not available for apps installed through Google Play. Contact support@truple.io with any questions.`"></v-switch>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'android' || device.os === 'kindle'">
                    <v-switch :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"
                              color="primary" persistent-hint
                              v-model="device.wifiUploadOnly"
                              @change="markDirty"
                              prepend-icon="wifi"
                              :label="`Wifi Preferred ${device.wifiUploadOnly ? 'Enabled' : 'Disabled'}`"
                              :hint="`When enabled, the device waits up to 12 hours for a wifi connection to upload screenshots.  After 12 hours screenshots will be uploaded via mobile data.  When disabled, device uploads screenshots immediately whether using a wifi connection or not.`">
                    </v-switch>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'android' || device.os === 'windows' || device.os === 'mac'">

<!--                    text redaction only available on android, windows 10 and macos 10.11+, see https://www.chromestatus.com/feature/5644087665360896.
                        However the deviceMarketName actually has the unix version of MacOS, not the actual MacOS version.  To get the MacOS version from
                         unix version see https://en.wikipedia.org/wiki/Darwin_(operating_system)#Release_history.

                         Thats why we ensure it starts with mac 15+.

                         Regex number range generator: http://gamon.webfactional.com/regexnumericrangegenerator/

                         //Update:  we simplified it to allow people to set it even if their device may not support it, because deviceMarketName wasn't always captured with earlier devices.
-->
                    <v-switch :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"
                              prepend-icon="short_text"
                              color="primary" persistent-hint
                              @change="markDirty"
                              v-model="device.redactText"
                              :label="`Redact text (black out words) ${device.redactText ? 'Enabled' : 'Disabled'}`"
                              :hint="`When enabled, the screenshots will have most words (text messages, email, web pages, etc.) blacked out.  When disabled, the screenshot will show all words.  Text redaction is only available on Android, Windows 10, and Mac OS v10.11+`">
                    </v-switch>

                    <div v-if="device.os === 'windows'"  class="pl-8">
                      <a :href="`https://${supportDomain}/articles/windows/windows-text-redaction-non-english`" target="_blank" class="font-italic caption">(requires English language installed)</a>
                    </div>

                  </v-col>
<!--                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'chromebook'">-->
<!--                    <v-switch :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"-->
<!--                              prepend-icon="format_shapes"-->
<!--                              color="primary" persistent-hint-->
<!--                              v-model="device.useExperimentalScreenCapture"-->
<!--                              :label="`Experimental Screenshot Generation ${device.useExperimentalScreenCapture ? 'Enabled' : 'Disabled'}`"-->
<!--                              :hint="`When enabled, screenshots will be generated based only off of the chrome's browser windows and all other windows will not be captured.  Enabling prevents the need for the screen capture permission, allows the app to black out banking websites, and works across multiple monitors, but will open up loopholes.  Disabling (recommended) will require the user to grant the screen capture permission each time the machine is restarted, screenshots will contain banking information, and screenshots will be true screenshots capturing everything on the screen.`">-->
<!--                    </v-switch>-->
<!--                  </v-col>-->
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.userAgent !== 'doh'">
                    <v-slider :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"
                              prepend-icon="blur_on"
                              persistent-hint
                              v-model="device.blurImages"
                              @change="markDirty"
                              track-color="light-gray"
                              color="accent"
                              :min="0"
                              :max="5"
                              :label="`Blur Screenshots`"
                              :hint="`Blur Screenshots: ${blurText(device.blurImages)}.  When enabled, the screenshots will be blurred.  Blurring screenshots can protect those viewing your reports from graphic details in images as well as your own privacy.  When disabled, the screenshot will show all details.`">
                    </v-slider>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.userAgent !== 'doh'" >
                    <v-slider :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"
                              prepend-icon="replay"
                              v-model="device.minutesPerScreenshot"
                              @change="markDirty"
                              track-color="light-gray"
                              color="accent"
                              :min="0.5"
                              :step="0.1"
                              :max="5.0"
                              :label="`Screenshot Interval`"
                      style="margin-bottom: -18px;"></v-slider>
                    <div class="pl-8" v-if="device.minutesPerScreenshot < 1.0">
                      <v-alert :value="true">
                        <div class="headline error--text">
                          <v-icon color="error">warning</v-icon>
                          WARNING
                          <v-icon color="error">warning</v-icon>
                        </div>
                        <div class="caption error--text">
                          {{ appName }} can only load ~1200 screenshots per device, per 24 hour period.  If more than 1200 screenshots are captured,
                          {{ appName }} may fail to load them and you won't be able to view any of the screenshots.
                          Average frequencies below 1 minute will drastically increase battery consumption & data usage.
                        </div>
                      </v-alert>
                    </div>
                    <div class="pl-8 caption font-italic" style="color: rgba(0,0,0,.54);">
                      <span class="font-weight-bold">Average Interval:</span>&nbsp;<span v-text="showTime(device.minutesPerScreenshot * 60.0 * 1000.0)"></span><br />
                      <span class="font-weight-bold">Minimum Interval:</span>&nbsp;<span v-text="intervalMin()"></span><br />
                      <span class="font-weight-bold">Maximum Interval:</span>&nbsp;<span v-text="intervalMax(device.minutesPerScreenshot * 60.0 * 1000.0)"></span><br />
                      <br />
                      <span>Note: Each interval is a uniformly distributed random value between the minimum interval and the maximum interval.  The screenshot timer is paused when the screen turns off or the phone goes idle.</span>
                    </div>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'android' || device.os === 'kindle'">

                    <div class="d-inline float-left">
                      <v-icon>pause_circle_outline</v-icon>
                    </div>
                    <div class="d-inline float-left ml-2" >
                      <div class="v-label theme--light mb-2 mt-1">Auto Paused / Whitelisted Apps</div>
                      <div v-for="eapa of getEnabledApaApps(device.autoPauseApps)" :key="`${device.deviceId}.${eapa.appId}`" class="caption text-center">
                        {{eapa.appName + (eapa.killApp ? ' (restart on pause enabled)' : '')}}
                      </div>

                      <div class="text-center mt-1 mb-1" style="width: 100%;">

                        <v-dialog v-model="showAutoPausedDialog" max-width="900" :fullscreen="$store.state.isMobile">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :disabled="$store.getters.isAccountLocked || !device.takeSnapshots"
                              color="primary"
                              label="Modify Auto Pause"
                              @click.stop="openAutoPause"
                              outlined
                            >
                              Modify
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title class="headline primary white--text">
                              Auto Paused / Whitelisted Apps
                              <v-spacer></v-spacer>
                              <v-btn icon dark @click="showAutoPausedDialog = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text class="mt-5">
                              <v-row v-for="apa of device.autoPauseApps" :key="`${device.deviceId}.${apa.appId}.custom`">
                                <v-col :cols="12" :md="4" :lg="6">
                                  <span class="text-primary title">{{apa.appName}}</span>&nbsp;<span class="caption font-italic">{{`(${apa.appId})`}}</span>
                                </v-col>
                                <v-col :cols="12" :md="8" :lg="6">
                                  <v-checkbox
                                    label="Enabled"
                                    hint="Enable or disable auto pause for this app"
                                    persistent-hint
                                    class="ma-0 pa-0"
                                    dense
                                    v-model="apa.autoPause"

                                  ></v-checkbox>
                                  <v-checkbox
                                    v-show="apa.autoPause"
                                    label="(Not Recommended) Restart on Pause"
                                    :hint="`Some apps (like hulu) need to have the ${appName} app restarted before they'll allow you to view the content.  If you find viewing the content doesn't work without this enabled, enable it and it should work.  We do not recommend enabling this unless you thoroughly test it on your device to ensure it doesn't open up loopholes, as it may depending on the device you have.`"
                                    persistent-hint
                                    class="ma-0 pa-0"
                                    dense
                                    :disabled="!apa.autoPause"
                                    v-model="apa.killApp"

                                  ></v-checkbox>
                                </v-col>
                                <v-col :cols="12" :md="12">
                                  <v-divider class="mt-2 mb-2" />
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-center">

                              <v-btn
                                @click.stop="addCustomAPA = true">
                                Add Custom
                              </v-btn>

                              <v-dialog v-model="addCustomAPA" max-width="400">
                                <template v-slot:activator="{ on }">

                                </template>
                                <v-card>
                                  <v-card-text>
                                    <v-text-field v-model="customApa.appName"
                                      placeholder="Netflix"
                                      hint="App name"
                                      primary
                                      autofocus
                                      persistent-hint >

                                    </v-text-field>
                                    <v-text-field v-model="customApa.appId"
                                      placeholder="com.netflix.mediaclient"
                                      primary
                                      hint="App Id"
                                      persistent-hint>

                                    </v-text-field>
                                    <div class="caption mt-4">
                                      Go to <a href="https://play.google.com/store/apps" target="_blank">play.google.com/store/apps</a> to search for the app id.<br /><br />
                                      <span class="font-italic">For example, Netflix's app id is <b>com.netflix.mediaclient</b>.  Netflix's Google Play url is: play.google.com/store/apps/details?id=<b>com.netflix.mediaclient</b>.</span>
                                    </div>
                                    <v-checkbox
                                      primary
                                      label="Enabled"
                                      v-model="customApa.autoPause">

                                    </v-checkbox>
                                    <v-checkbox
                                      primary
                                      label="Restart App on Pause"
                                      hint="Do not enable this unless you still get an error message even after the screencast permission has disappeared from your notification bar."
                                      v-model="customApa.killApp">

                                    </v-checkbox>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-btn
                                      @click="addCustomAPA = false">
                                      close
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      primary
                                      @click="addCustomAPAToDevice(device)">
                                      Add
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>


                            </v-card-actions>
                          </v-card>
                        </v-dialog>


                      </div>
                      <div class="caption font-italic theme--light v-messages">Click <a :href="`https://${supportDomain}/articles/android/screenshot-accountability-streaming`" target="_blank">here</a> for more info on auto pause.</div>

                      <v-alert v-if="apaOpened" dense outlined type="warning">You must save your changes and then restart the device for changes to auto-pause apps to take effect.</v-alert>
                    </div>

                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.userAgent !== 'doh'">
                    <v-switch :disabled="!device.takeSnapshots || $store.getters.isAccountLocked"
                              prepend-icon="report"
                              color="primary" persistent-hint
                              @change="markDirty"
                              v-model="device.rateScreenshots"
                              :label="`Rate screenshots ${device.rateScreenshots ? 'Enabled' : 'Disabled'}`"
                              :hint="`When enabled, screenshots will be analyzed for risk.  Enabling will increase cpu, ram, and battery usage and may slow your device down.`">
                    </v-switch>
                    <v-fade-transition>
                      <v-slider
                          v-if="device.takeSnapshots && device.rateScreenshots"
                          @change="markDirty"
                          :disabled="!device.takeSnapshots || !device.rateScreenshots || $store.getters.isAccountLocked"
                                prepend-icon="slow_motion_video"
                                persistent-hint
                                v-model="device.sensitivity"
                                track-color="light-gray"
                                color="accent"
                                :min="0"
                                :max="4"
                                :label="`Algorithm Sensitivity`"
                                :hint="`Sensitivity: ${sensitivityText(device.sensitivity)}.  Decreasing sensitivity will cause fewer screenshots to be marked high risk due to content analysis, but may cause the algorithm to miss some high risk screenshots.`">
                      </v-slider>
                    </v-fade-transition>
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1" v-if="device.os === 'android' || device.os === 'kindle'">
                    <v-switch :disabled="!device.takeSnapshots || !$store.getters.isE2EEEnabled"
                              @change="markDirty"
                              persistent-hint
                              v-model="device.allowFinancialAndMedicalApps"
                              color="primary"
                              label="Allow screen capture of financial and medical apps"
                              :hint="`${appName} typically prevents screenshots from being captured of financial and medical apps & websites.  However on some android devices, some of the apps (but not websites) could potentially be used to bypass ${appName}.  Enable this to allow capturing screenshots of those apps.  ${appName} still won't capture banking websites.  End-to-end encryption is required in order to enable.`"
                              />
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="4" class="pr-5 pl-2 pb-1 pt-1">

                    <v-checkbox
                            :disabled="$store.getters.isAccountLocked"
                            prepend-icon="delete"
                            color="primary" persistent-hint
                            v-model="device.delete"
                            @change="deleteClicked($event, device)"
                            :label="`Delete`"
                            :hint="`Devices may not automatically delete when the ${appName} app is uninstalled.  You'll need to delete them here once you've uninstalled them.`"
                    ></v-checkbox>

                    <v-alert type="warning" outlined class="mt-2 mb-0" v-if="device.delete && device.userAgent === 'doh'">
                      If you delete the device, without first removing {{ appName }} from the device, the internet will stop working on the iPhone/iPad!
                      <br />
                      <a href="https://blog.truple.io/2020/09/21/remove-doh-from-ios.html" target="_blank">View instructions for removing
                        {{ appName }} from the device.</a>
                    </v-alert>

                  </v-col>
                  <v-col :cols="12" class="pr-5 pl-2 pb-1 pt-1">
                    <v-btn text class="font-italic font-weight-light float-end caption text-capitalize" v-if="!device.showDeviceId" @click="device.showDeviceId = true" :disabled="device.showDeviceId">
                      Show Device Id
                    </v-btn>
                    <span class="font-italic font-weight-light float-end caption pt-5" v-else>
                      {{`Device Id: ${device.deviceId}`}}
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div v-else class="text-center pa-3 mx-3" style="border: dashed lightgray 1px;">
            You have no devices on your account.  To get started <a @click.stop="addDeviceButton()">add a device</a>.
          </div>

          <v-container>
            <v-row v-if="dohDeletedDevices.length > 0">
              <v-col :cols="12" class="pl-2 pr-2 mt-2 d-block">

                <div class="float-right">

                  <div>Have you removed {{ appName }} from these devices?</div>
                  <div class="caption">If you fail to remove {{ appName }} from the device, deleting the device will cause the device to lose it's internet connection.</div>
                  <div class="caption text-right">
                    <a href="https://blog.truple.io/2020/09/21/remove-doh-from-ios.html" target="_blank">See how to remove
                      {{ appName }}</a>
                  </div>

                  <v-card v-for="d in dohDeletedDevices" :key="`${d.deviceId}_delete_verify_2`" style="height: 53px;" outlined>
                    <v-card-text>
                      <span class="float-left pr-1">{{d.deviceName}}</span>
                      <v-checkbox
                        dense
                        class="float-right ma-0 pa-0"
                        v-model="d.hasRemovedDoh"
                      />
                      <span class="float-right pr-1">Check to verify removed</span>
                    </v-card-text>
                  </v-card>
                </div>

              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" d-flex class="pl-4 pr-4">
                <v-btn @click.stop="addDeviceButton()" outlined color="primary" v-if="!cantSave">
                  <v-icon left>add</v-icon>
                  Add a device
                </v-btn>
                <div class="float-right">
                  <div v-if="cantSave" class="caption error--text text-right mr-2 float-left">
                    Please verify you've removed {{ appName }} from the iOS devices before saving.
                  </div>
                  <v-btn
                      class="float-right"
                    outlined
                    @click="saveDeviceSettings()"
                    color="primary"
                    :disabled="$store.getters.isAccountLocked || cantSave">
                    Save
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col :cols="12"><span class="caption" style="font-style: italic;">Emails are sent to the account owner and everyone who receives reports when device settings changes are made.</span></v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showMessage"
      :timeout="750"
      :bottom="true">
      {{ message }}
      <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="showMessage = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>

    <v-dialog v-model="iosDeleteAlertDialog" max-width="350px">
      <v-card>
        <v-card-title>
          <span class="title">Delete iOS Device</span>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="iosDeleteAlertDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          You <b>MUST</b> remove {{ appName }} from the device prior to deleting, or else the device will lose it's internet connection!
        </v-card-text>
        <v-card-actions class="d-block">
          <v-btn color="primary" block @click="openiOSRemoveInstructions">View instructions</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDirtyWarning" max-width="400">
      <v-card>
        <v-card-title>
          Unsaved Changes
        </v-card-title>
        <v-card-text>
          You have unsaved changes.  Please save them if you wish to keep them.
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="showDirtyWarning = false" color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import logging from "../../lib/logging";
  import Loading from '../Loading'
  import StoreConstants from '../../StoreConstants'
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";
  import filters from "../../services/Filters";
  import SdnsStamp from "../../services/doh/SdnsStamp";
  import DNSCloakHelper from "../../services/DNSCloakHelper";
  import E2EEHelperCache from "../../services/e2ee/E2EEHelperCache";
  import AddDeviceDialog from "@/components/devices/AddDeviceDialog";

  let beforeunload = null
  let _e2eeHelper = null

  export default {
    name: 'devices',
    components: {AddDeviceDialog, Loading},
    created () {
    },
    data () {
      return {
        appName: process.env.VUE_APP_NAME,
        supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
        blockUninstallAgreementChecks: {
          own: false,
          used: false,
          difficult: false
        },
        iosDeleteAlertDialog: false,
        apaOpened: false,
        message: '',
        showMessage: false,
        filters: filters,
        showAutoPausedDialog: false,
        windowsDownloadUrl: 'https://windows.truple.io/prod/truple.installer.v2.exe',
        androidDownloadUrl: 'https://play.google.com/store/apps/details?id=com.camhart.netcountable',
        macDownloadUrl: '',
        addDeviceModal: false,
        msg: 'devices',
        deviceTypes: ['OWNER', 'ADMIN', 'USER'],
        loading: true,
        changesMade: false,
        devices: [],
        instructionsData: {
          expires: 0,
          inviteCode: '',
          accountId: ''
        },
        availableOS: ['android', 'windows'],
        addDeviceStepper: 1,
        addDevice: {
          os: null,
          osTemp: -1,
          name: '',
          takeSnapshots: false,
          adminDeviceType: false,
          wifiUploadOnly: false,
          redactText: true,
          blurImages: 1,
          rateScreenshots: false
        },
        customApa: {
          appName: '',
          appId: '',
          autoPause: true,
          killApp: false
        },
        saveSuccessfulMessage: false,
        currentSubscriptionPlanDeviceLimit: 0,
        currentNumberOfDevicesBeingMonitored: 0,
        addCustomAPA: false,
        dirty: false,
        showDirtyWarning: false,
        showBlockUninstallWarning: false
      }
    },
    beforeRouteLeave (to, from, next) {
      if(this.showDirtyWarningDialog()) {
        next(false)
      } else {
        next()
      }
    },
    methods: {
      showDirtyWarningDialog() {
        if(this.dirty) {
          this.dirty = false
          this.showDirtyWarning = true
          return true
        } else {
          return false
        }
      },
      blockUninstallWarningCancel() {
        this.devices[this.blockUninstallWarningIndex].blockUninstall = false
        this.showBlockUninstallWarning = false
      },
      blockUninstallWarningAgree() {
        this.markDirty()

        this.devices[this.blockUninstallWarningIndex].blockUninstall = true
        this.showBlockUninstallWarning = false
      },
      blockUninstallChange(deviceIndex) {
        this.blockUninstallWarningIndex = deviceIndex
        this.showBlockUninstallWarning = this.devices[deviceIndex].blockUninstall //show the warning when we're enabling it.
        this.blockUninstallAgreementChecks.own = false
        this.blockUninstallAgreementChecks.used = false
        this.blockUninstallAgreementChecks.difficult = false
      },
      markDirty() {
        this.dirty = true
      },
      openAutoPause() {
        this.markDirty()
        this.showAutoPausedDialog = true
        this.apaOpened = true
      },
      openiOSRemoveInstructions() {
        window.open('https://blog.truple.io/2020/09/21/remove-doh-from-ios.html', '_blank')
      },
      deleteClicked($event, device) {
        this.markDirty()
        if(device.userAgent === 'doh' && device.delete) {
          this.iosDeleteAlertDialog = true;
        }
      },
      async getE2EEHelper() {
        if(_e2eeHelper == null) {
          await this.$store.state.accountLoadedPromise
          // _e2eeHelper = new E2EEHelper(this.$store.state.account.accountId, this.$store.state.account.account.altAccountId)
          _e2eeHelper = E2EEHelperCache.getE2EEHelper(this.$store.state.account.accountId, this.$store.state.account.account.altAccountId)
        }
        return _e2eeHelper
      },
      copyToClipboard() {
        this.message = 'copied to clipboard'
        this.showMessage = true
      },
      resetSdnsStamp(device) {
        this.markDirty()
        device.hasChanged = true
        device.dnsCloakConfig = SdnsStamp.calculateConfig(device.filterId, device.deviceId, device.deviceName)
        device.mailToLink = DNSCloakHelper.getMailLink(device.filterId, device.deviceId, device.deviceName)
        device.instructionsLink = DNSCloakHelper.getInstructionsLink(device.filterId, device.deviceId, device.deviceName)
      },
      open(url) {
        window.open(url, '_blank')
      },
      getEnabledApaApps(apps) {
        return apps.filter(x => { return x.autoPause });
      },
      addCustomAPAToDevice(device) {

        device.autoPauseApps.push(this.customApa)

        this.customApa = {
          appName: '',
          appId: '',
          autoPause: true,
          killApp: false
        }
        this.addCustomAPA = false
      },
      // download(url) {
      //   let link = document.createElement("a");
      //   link.download = url
      //   link.target = "_blank"
      //
      //   // Construct the URI
      //   link.href = url
      //   document.body.appendChild(link)
      //   link.click()
      //
      //   // Cleanup the DOM
      //   document.body.removeChild(link)
      // },
      addDeviceButton() {

        this.addDeviceModal = true
      },
      sensitivityText(amount) {
        switch(amount) {
          case 4:
            return 'Extremely Sensitive';
          case 3:
            return 'Very Sensitive';
          case 2:
            return 'Moderately Sensitive';
          case 1:
            return 'Less Sensitive';
          case 0:
            return 'Least Sensitive';
          default:
            return 'Unknown';
        }
      },
      blurText(amount) {
        switch(amount) {
          case 0:
            return 'No blur';
          case 1:
            return 'Slight Blur';
          case 2:
            return 'Moderate Blur';
          case 3:
            return 'Serious blur';
          case 4:
            return 'Severe Blur';
          case 5:
            return 'Max Blur';
          default:
            return 'No blur';
        }
      },
      async saveDeviceSettings() {
        this.loading = true
        this.dirty = false
        let devicesToSave = []

        let devicesIdsToDeleteE2eeCardFor = []

        let e2eeHelper = await this.getE2EEHelper()

        for(let c = 0; c < this.devices.length; c++) {
          devicesToSave.push({
            // os: this.devices[c].os,
            filterId: this.devices[c].filterId,
            deviceType: this.devices[c].deviceType,
            takeSnapshots: this.devices[c].takeSnapshots,
            wifiUploadOnly: this.devices[c].wifiUploadOnly,
            blockUninstall: this.devices[c].blockUninstall,
            deviceId: this.devices[c].deviceId,
            deviceName: this.devices[c].deviceName,
            adminDevice: this.devices[c].adminDevice,
            imagesPerMonth: (31.0 * 4.0 * 60.0 * 60.0 * 1000.0) / (parseFloat(this.devices[c].minutesPerScreenshot) * 60.0 * 1000.0),
            blurImages: this.devices[c].blurImages,
            redactText: this.devices[c].redactText,
            rateScreenshots: this.devices[c].rateScreenshots,
            autoPauseApps: this.devices[c].autoPauseApps,
            sensitivity: this.devices[c].sensitivity,
            allowFinancialAndMedicalApps: this.devices[c].allowFinancialAndMedicalApps,
            useExperimentalScreenCapture: this.devices[c].useExperimentalScreenCapture,
            delete: this.devices[c].delete
          });


          if(e2eeHelper.e2eeEnabled && this.devices[c].delete) {
            devicesIdsToDeleteE2eeCardFor.push(this.devices[c].deviceId)
          }
        }

        try {
          let result = await this.api.updateDevicesAwait(devicesToSave)

          if(result.status === 200) {
            if(devicesIdsToDeleteE2eeCardFor.length > 0) {
              try {
                await e2eeHelper.revokeCardsForDeletedDevices(new Set(devicesIdsToDeleteE2eeCardFor))
              } catch(e) {
                console.log(e)
              }
            }

            this.saveSuccessfulMessage = true
            this.apaOpened = false

          } else {
            throw new Error("something went wrong " + result.status)
          }
        } catch (err) {
          logging.notify(new Error(JSON.stringify({
            obj: devicesToSave,
            error: err
          })))
          console.log(err)
          alert('error saving results... ' + JSON.stringify(err))
        } finally {
          this.loadDevices()
        }
      },
      async loadDevices() {
        this.loading = true

        this.currentNumberOfDevicesBeingMonitored = 0

        this.loadSubscriptionInfo()

        this.$store.dispatch(StoreConstants.actions.loadDevices) // TODO:  simplify all this, have Account reply on the store model
        this.$store.dispatch(StoreConstants.actions.loadAccount) // TODO:  simplify all this, have Account reply on the store model

        let result = await this.api.getDevicesAwait()

        for(let c = 0; c < result.data.length; c++) {

          if(result.data[c].takeSnapshots) {
            this.currentNumberOfDevicesBeingMonitored++;
          }

          if(!Number.isInteger(result.data[c].sensitivity)) {
            result.data[c].sensitivity = 2
          }

          if(result.data[c].userAgent === 'doh') {
            result.data[c].dnsCloakConfig = SdnsStamp.calculateConfig(result.data[c].filterId, result.data[c].deviceId, result.data[c].deviceName)
            result.data[c].mailToLink = DNSCloakHelper.getMailLink(result.data[c].filterId, result.data[c].deviceId, result.data[c].deviceName)
            result.data[c].instructionsLink = DNSCloakHelper.getInstructionsLink(result.data[c].filterId, result.data[c].deviceId, result.data[c].deviceName)
          }

          result.data[c].delete = false
          result.data[c].showDeviceId = false

          result.data[c].minutesPerScreenshot = (31.0 * 4.0 * 60.0 * 60.0 * 1000.0) / parseFloat(result.data[c].imagesPerMonth) / (60.0 * 1000.0)
          // result.data[c].imagesPerMonth = -1 * result.data[c].imagesPerMonth
          result.data[c].os = result.data[c].os ? result.data[c].os : 'android'
        }

        result.data.sort((a, b) => {
          if(a.deviceType === b.deviceType) {
            if(a.deviceName < b.deviceName) {
              return -1
            } else if(a.deviceName > b.deviceName) {
              return 1
            } else {
              return 0
            }
          } else if(a.deviceType === "OWNER") {
            return -1
          } else if(a.deviceType === "ADMIN") {
            if(b.deviceType === "OWNER") {
              return 1
            } else {
              return -1
            }
          } else if(a.deviceType === "USER") {
            return 1
          }
        })

        this.devices = result.data

        this.loading = false
      },
      intervalMin() {
        return '15 seconds'
      },
      intervalMax(time) {
        return this.showTime(time * 2 - 15000)
      },
      showTime(time) {
        var CONST_SECONDS = 1000;
        var CONST_MINUTES = 60 * CONST_SECONDS;
        var CONST_HOURS = 60 * CONST_MINUTES;
        var CONST_DAYS = 24 * CONST_HOURS;

        var days = Math.floor(time / CONST_DAYS);
        var hours = Math.floor((time - days * CONST_DAYS) / CONST_HOURS);
        var minutes = Math.floor((time - days * CONST_DAYS - hours * CONST_HOURS) / CONST_MINUTES);
        var seconds = Math.floor((time - days * CONST_DAYS - hours * CONST_HOURS - minutes * CONST_MINUTES) / CONST_SECONDS);

        var sb = "";
        if(days > 0) {
          sb += days + " day" + (days > 1 ? 's ' : ' ');
        }
        if(hours > 0) {
          sb += hours + " hour" + (hours > 1 ? 's ' : ' ');
        }
        if(minutes > 0) {
          sb += minutes + " minute" + (minutes > 1 ? 's ' : ' ');
        }
        if(seconds > 0) {
          sb += seconds + " second" + (seconds > 1 ? 's' : '');
        }
        return sb.trim();
      },
      async loadSubscriptionInfo() {
        this.currentSubscriptionPlanDeviceLimit = 15
        let response = await this.api.getAccountAwait(true)
        if(response.data.account.stripeInfo && response.data.account.stripeInfo.length > 0) {
          this.currentSubscriptionPlanDeviceLimit = response.data.account.stripeInfo[response.data.account.stripeInfo.length - 1].planDeviceTotal
        } else {
          this.currentSubscriptionPlanDeviceLimit = 0
        }
      }
    },
    computed: {
      getInviteCodeEmailBody() {
        return encodeURIComponent(`Follow the instructions at the link below to add your device to ${this.instructionsData.accountId}'s ${process.env.VUE_APP_NAME} account.  The invite code below expires in 30 minutes, so please do so immediately.

Instructions: https://${process.env.VUE_APP_SUPPORT_DOMAIN}/articles/misc/invite-code-instructions
Account Code: ${this.instructionsData.accountId}
Invite Code: ${this.instructionsData.inviteCode} (expires in 30 minutes)
`)
      },
      cantSave() {
        if(this.dohDeletedDevices.length > 0) {
          for(let d of this.dohDeletedDevices) {
            if(!d.hasRemovedDoh) {
              return true
            }
          }
        }

        return false
      },
      dohDeletedDevices() {
        if(this.devices) {
          let filtered = this.devices.filter(x => {
            return x.userAgent === 'doh' && x.delete
          })

          return filtered
        } else {
          return []
        }
      },
      currentDevice() {
        if(this.$route.query.currentDevice) {
          let currentDevice = decodeURIComponent(this.$route.query.currentDevice).split('').reverse().join('')
          localStorage.setItem('currentDevice', currentDevice)
          return currentDevice
        } else {
          let currentDevice = localStorage.getItem('currentDevice')
          return currentDevice
        }
      }
    },
    mounted() {

      beforeunload = (e) => {
        if(this.showDirtyWarningDialog()) {
          e.preventDefault()
          e.returnValue = 'You have unsaved changes.  Are you sure you wish to leave?'
          this.showDirtyWarning = false
          return e.returnValue
        }
      }
      window.addEventListener('beforeunload', beforeunload)

      this.loadDevices()

      this.getE2EEHelper() //prefetch e2eehelper
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', beforeunload)
    }
  }
</script>


<style lang="less" scoped>

  .expandable-title {
    cursor: pointer;
    cursor: hand;
  }


  /*can't get this to work...*/
  .force-pointer > * {
    cursor: pointer;
    cursor: hand;
  }
</style>